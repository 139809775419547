@import 'bootstrap-custom-variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

.reviewDialogImageOptions,
.powerProfileDialogImageOptions {
    background-color: $mako-black;
    border-radius: $border-radius;
}

.body,
.content-section {
    background-color: $outer-space;
    color: $seashell;
}

mpc-modal .mpc-modal .mpc-modal-body {
    background: $outer-space;
    color: $seashell;
}

.input-group-addon {
    &.open {
        background-color: $dark-white;
    }

    &.blocked {
        background-color: #919293;
        right: 0;
        padding-left: 10px;
        padding-right: 5px;
    }
}

a:not(.nav-link):not(.dropdown-item) {
    color: $secondary !important;
}

.productdatasheetlink {
    color: $alto-grey !important;
}

nav {
    a {
        color: white !important;
    }

    a.dropdown-item {
        color: $mineshaft !important;
    }
}

th {
    color: $seashell;
}

.btn-inverted {
    background-color: $bondi-blue;
    color: $seashell;
}

.close {
    color: white !important;
}

/* Toastr modifications */
.toast-container {
    .toast {
        width: 450px;
    }

    margin-bottom: 60px;

    .toast-info {
        background-image: none !important;
        background-color: $mako;
        border-color: $mako;
        color: white;
    }
}

.toast-container > .toast-info:before {
    font-size: 24px;
    line-height: 18px;
    float: left;
    color: #fff;
    padding-right: 0.5em;
    margin: auto 0.5em auto -1.5em;
}

.modal-content {
    background-color: $mineshaft !important;

    .modal-title {
        color: $seashell;
    }

    .modal-footer {
        color: $seashell;
        background-color: $mineshaft;
    }

    .modal-body {
        color: $seashell;
        background-color: $mineshaft;
    }

    .modal-overlay {
        background: $mineshaft;
    }
}

.main {
    color: $seashell;
    background: url('/assets/images/eye-dark.png');
}

.content {
    color: white;
}

.title-border-bottom {
    border-bottom: solid 2px $alto-grey !important;
}

.title-border-bottom2 {
    border-bottom: solid 2px $white !important;
}

.title-border-top {
    border-top: solid 2px $alto-grey !important;
}

.dreamlitehistory {
    .selected {
        background-color: #1f6b51;
        color: $dark-white;
    }
    table {
        th,
        td {
            border: 1px solid $dark-white;
        }
    }
}

.pagination {
    button {
        border-color: #e0e0e0 !important;
        color: #fff;

        &.selectedPage {
            background: #fff;
            color: #333;
        }
    }
}

.range-slider {
    .range-slider_dots {
        .dot:before {
            background: #fff;
        }
    }

    .range-slider_range {
        background: #f2f2f2;

        &::-webkit-slider-thumb {
            background: $procornea-green;
        }

        &::-moz-range-thumb {
            background: $procornea-green;
        }
    }

    .range-slider_value {
        color: #fff;
        background: $procornea-green;

        &:after {
            border-right-color: $procornea-green !important;
        }
    }
}

.notepanel {
    .notecontainer {
        .notewindow {
            background-color: $dark-grey-alternative;
        }
    }

    .note-psside {
        background-color: $abby-grey;
    }

    .ps-chat {
        .nomessages {
            background: $dark-grey-alternative !important;
        }

        .chat-type {
            background: rgba($dark-grey-alternative, 0.8);
            color: #fff;
        }

        .client-communication {
            background: $dark-grey-alternative;
        }
    }
}

.selectallwrapper,
.measurement-block {
    .status,
    .checkedIcon {
        border-color: #fff;
    }
}

.ps-datatable {
    .table_entry {
        border-bottom: 1px solid #444444;

        &:hover,
        &_selected {
            background: $procornea-green;
        }
    }
}

.form-control {
    background-color: $seashell !important;
}

.search {
    input {
        background-color: rgba(0, 0, 0, 0.6) !important;
        color: white !important;
    }

    .input-group-text {
        background-color: rgba(0, 0, 0, 0.6) !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: white;
    }
}

.home-nav.cards {
    .card {
        @include gradient-y(
            $start-color: $rollingstone-grey,
            $end-color: $rainy-grey,
            $start-percent: 0%,
            $end-percent: 100%
        );
        background-color: $rainy-grey !important;
        border-color: $rollingstone-grey !important;
    }

    .card:hover {
        @include gradient-y(
            $start-color: rgba($bondi-blue, 0.8),
            $end-color: $bondi-blue,
            $start-percent: 0%,
            $end-percent: 100%
        );
        background-color: white !important;
        border-color: $bondi-blue !important;
    }
}

.cards {
    .card {
        color: white;
        background-color: $rainy-grey;
        border-color: $silver;
        box-shadow: 0 0 10px $space-black;

        .card-body {
            .info {
                color: $silver;
            }
        }
    }

    .card:hover {
        color: white;
        background-color: $bondi-blue;
    }
}

.cards:not(.home-nav) {
    .card {
        .card-header {
            background-color: $mako-black;
        }

        .card-header-tabs {
            border-bottom: 1px solid $rollingstone-grey;
        }

        .card-body {
            background-color: $mako-black;
        }
    }
}

.landingBackground {
    background: $outer-space url('/assets/images/bg-eye-dark.png') -600px -200px no-repeat;
}

hr {
    color: $rollingstone-grey;
    background-color: $rollingstone-grey;
}

footer {
    background-color: $abby-grey;
    color: $seashell;

    a {
        color: $seashell !important;
        text-decoration: none !important;
    }
}

.content a {
    color: white;
}

.clientsearchresult:hover,
.recentlyopenedclientsresult:hover {
    background-color: $rainy-grey;
}

.imageSun::before {
    color: $space-black;
}

.accented-background {
    background-color: $mako !important;
}

.accented-background2 {
    background-color: $pro-black;
}

.accented-background3 {
    background-color: $shuttle-grey;
}

.default-message {
    color: $shuttle-grey;
}

.collapse-label {
    color: $seashell;
}

.process-nav {
    background-image: linear-gradient(to right, $capecod-grey, $capecod-grey 50%, $abby-grey 1px);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

    .process-nav-1 {
        background-image: linear-gradient(to right, $capecod-grey 10px, $capecod-grey, $rollingstone-grey);
    }

    .process-nav-2 {
        background-color: $abby-grey;
    }

    .step-past .step-inner,
    .step-current .step-inner {
        border-top: solid 4px $capecod-grey;
        border-bottom: solid 4px $capecod-grey;
    }

    .arrow {
        border-left: 19px solid $capecod-grey;
        background-color: $abby-grey;
    }

    .arrow:after {
        border-left: 16px solid $rollingstone-grey;
    }
}

.process-nav-ps {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    background-image: linear-gradient(to right, $capecod-grey 10px, $capecod-grey, $rollingstone-grey);
}

.continue-process,
.spare-warning {
    background: rgba(72, 78, 83, 0.95);
}

.loadingoverlay {
    color: $seashell;
    background-color: rgba(0, 0, 0, 0.6);

    .loading {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
        background-color: $abby-grey;
    }
}

.headericon,
.returnIcon {
    filter: invert(1);
}

.clienttabs {
    .client-tab:not(.active) {
        a:hover {
            color: white !important;
        }

        a {
            color: $rainy-grey !important;
        }
    }
}

.btn-link {
    background-color: $greenhaze !important;
    border: none !important;
    color: $white !important;
}

.btn-secondary,
.btn-default {
    background-color: $rollingstone-grey !important;
    border: none !important;

    &:hover,
    &.active {
        background-color: darken($rainy-grey, 10%) !important;
    }
}

.pre {
    background-color: $mako;
    color: white !important;
    font-family: monospace;
    white-space: pre;
}

.client-communication {
    background-color: $mako;

    .message-content,
    .attachment-content {
        background-color: $white;
        color: $mineshaft;

        &.sent {
            background-color: $primary-selected;
            color: $white;
        }

        &.received {
            background-color: lighten($silver, 10%);
        }
    }
}

.datatable tbody tr {
    background-color: darken($abby-grey, 10%);

    &:hover {
        background-color: darken($abby-grey, 15%);
    }
}

.datatable {
    a {
        color: $alto;
    }

    .selected {
        background-color: $primary-selected;

        &:hover {
            background-color: darken($primary-selected, 5%);
        }
    }
}

.orderlineicon {
    filter: invert(1);
}

.tab-container {
    .nav-tabs {
        border-bottom-color: $rainy-grey !important;

        .nav-item {
            .active {
                background-color: $mako !important;
            }

            .nav-link {
                border-bottom: 0;

                &.active {
                    border-left-color: $rainy-grey !important;
                    border-top-color: $rainy-grey !important;
                    border-right-color: $rainy-grey !important;
                }

                h3 {
                    color: $seashell !important;
                }
            }

            :hover {
                border-left-color: $rainy-grey !important;
                border-top-color: $rainy-grey !important;
                border-right-color: $rainy-grey !important;
            }
        }
    }

    .tab-pane.active {
        background-color: $mako !important;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-left: $nav-tabs-border-width solid $rainy-grey;
        border-right: $nav-tabs-border-width solid $rainy-grey;
        border-bottom: $nav-tabs-border-width solid $rainy-grey;
    }
}

.measurementitemcontainer {
    background-color: lighten($mako-black, 5%);

    &:hover {
        background-color: lighten($abby-grey, 10%);
    }

    &.selected {
        background-color: $bondi-blue;
    }
}

.subjectivemeasurement {
    background-color: $mako-black;
}

.previousmeasurement {
    background-color: $pro-black;
}

.currentmeasurement {
    background-color: $mako-black;
}

.lenstype {
    background-color: darken($rainy-grey, 5%);
    border-color: lighten($rainy-grey, 5%);

    .agreement button {
        color: $white;
    }

    .lenstypeIcon {
        filter: invert(0.9);
    }

    &.disabled {
        color: lighten($rainy-grey, 5%);
        background-color: darken($rainy-grey, 15%);
    }
}

.lensparameters {
    background-color: $mako-black;
}

.diagnosis {
    background-color: $mako-black;
}

.disabled {
    color: rgba($color: $seashell, $alpha: 0.4) !important;
}

.badge-outline {
    border: solid 1px $seashell;
}

tabset {
    .nav-link {
        color: $seashell !important;
    }
}

.fileupload-wrapper {
    border: 1px solid darken($rainy-grey, 10%);
    background-color: darken($rainy-grey, 5%) !important;
}

.fileupload {
    .drop-container {
        border: 2px dashed darken($rainy-grey, 15%);
    }
}

.pills-tab {
    border-bottom: 1px $alto solid;

    &.active {
        background-color: $rainy-grey;
        color: $white;
    }

    &:hover {
        color: $white;
    }
}

.tabs {
    a.nav-link {
        color: $primary !important;

        &.active {
            background-color: $rainy-grey;
            color: $white !important;
        }

        &:hover {
            color: $white !important;
        }

        border-bottom: 1px solid $secondary;
    }
}

.tabcontent {
    background-color: $abby-grey;
}

.settingsbody {
    background-color: $rainy-grey;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: white;
    }
}

.ld-check {
    color: $rainy-grey;
}

.message-container {
    background: url('/assets/images/order-background-dark.svg') no-repeat;
    background-size: auto 100%;
    background-position: top right;
    height: calc(100vh - 160px);
}

@include media-breakpoint-up(md) {
    fitting-client-search .accented-background {
        box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.3);
    }
}

@include media-breakpoint-down(sm) {
    fitting-client-search .accented-background {
        box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.3);
    }

    .message-container {
        background-color: $space-black !important;
    }
}

.orderstatusitem,
.serviceitem {
    background-color: lighten($mako-black, 5%);

    &:hover {
        background-color: lighten($abby-grey, 10%);
    }

    &.selected {
        background-color: $bondi-blue;
    }

    .statusbar {
        background-color: lighten($abby-grey, 5%);
        border-left-color: lighten($abby-grey, 5%);
    }
}

.orderstatusfilterbar,
.servicefilterbar {
    background-color: lighten($abby-grey, 5%);
}

.search {
    input,
    .input-group-append {
        color: $seashell !important;
        border-bottom: 2px solid $gray !important;
    }

    input::placeholder {
        color: $gray !important;
    }

    .input-group-text {
        color: $seashell !important;
    }
}

.sort,
.filter {
    button {
        color: $seashell !important;
        border-bottom: 2px solid $gray !important;
    }
}

.shop-sidebar .menuitem {
    &.selected,
    &:hover {
        color: $white;
        background-color: $abby-grey;
    }
}

@include media-breakpoint-down(lg) {
    .shop-contentwrapper.accented-background {
        box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.3);
    }
}

@include media-breakpoint-up(lg) {
    .shop-contentwrapper.accented-background {
        box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.3);
    }
}

.shopitem {
    background-color: lighten($mako-black, 5%);

    &:hover {
        background-color: lighten($abby-grey, 10%);
    }

    &.selected {
        background-color: $bondi-blue;
    }
}

shop-product .lensparameters {
    background-color: $pro-black;
}

.form-control-plaintext {
    color: $seashell !important;
}

table.shoppingcart {
    tbody {
        td.first {
            border-left: 1px solid $shuttle-grey;
        }

        td.last {
            border-right: 1px solid $shuttle-grey;
        }

        td {
            border-top: 1px solid $shuttle-grey;
            border-bottom: 1px solid $shuttle-grey;
        }
    }
}

tr.hover-effect:hover {
    background: $shuttle-grey;
}

.orderreferencepanel {
    background-color: $abby-grey;
}

.visavyCE {
    background: url('/assets/images/visavy-ce-dark.png');
}

.clientnotesbackground {
    background-color: $mako-black;
}

.theme-opposite {
    background-color: $dark-white;
    color: black;
}

.grey-primary {
    background-color: $dark-grey-primary;
    color: white;
}

.grey-secondary {
    background-color: $dark-grey-secondary;
    color: white;
}

.grey-alternative {
    background-color: $dark-grey-alternative;
    color: white;
}

.disabled {
    background-color: $dark-grey-primary;
    color: darken($dark-grey-primary, 20%) !important;
}

.mergeclient {
    background-color: $abby-grey;
}

.mergeclient:hover {
    background-color: $rollingstone-grey;
}

.service-client {
    background-color: $abby-grey;

    &:hover {
        background-color: darken($abby-grey, 5%);
    }
}

.service-lensselecttablerow {
    border-radius: $border-radius;
    background-color: $abby-grey;

    &:hover {
        background-color: darken($abby-grey, 5%);
    }
}

.selected {
    border-radius: $border-radius;
    background-color: $primary-selected;

    &:hover {
        background-color: darken($primary-selected, 10%);
    }
}

.sticky-element {
    background: rgba(49, 52, 54, 0.95);
}

.table {
    color: #fff !important;
}
.table-item:hover {
    background-color: $dark-grey-secondary;
}

.disabledConfig {
    background: rgba(60, 66, 72, 0.9);
}

.wp-block-table table {
    width: 100%;
    border: 1px solid #fff;
    margin-bottom: 20px;

    tr {
        td {
            border: 1px solid #fff;
            padding: 5px;
        }
    }
}

.accordion {
    --bs-accordion-color: #{$seashell} !important;
    --bs-accordion-bg: #{$dark-grey-secondary} !important;
    --bs-accordion-border-color: #{$dark-grey-primary} !important;
    --bs-accordion-btn-color: #{$seashell} !important;
    --bs-accordion-btn-bg: #{$dark-grey-secondary} !important;
    --bs-accordion-btn-focus-border-color: #{$dark-grey-primary} !important;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #{$dark-grey-primary} !important;
    --bs-accordion-active-color: #{$seashell} !important;
    --bs-accordion-active-bg: #{$dark-grey-primary} !important;
}

.editor {
    border-radius: 4px;
    margin-top: 1rem;
    text-align: left;

    .NgxEditor__MenuBar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        background-color: $dark-grey-primary;
    }

    .NgxEditor__MenuItem--IconContainer:hover {
        color: black;
    }

    .NgxEditor__MenuItem--Text:hover {
        color: black;
    }

    .NgxEditor__Dropdown--Item {
        color: black;
    }

    .NgxEditor__Dropdown--Text:hover {
        color: black;
    }

    .NgxEditor__Dropdown--DropdownMenu {
        .NgxEditor__Dropdown--Item {
            color: black;
        }
    }

    .NgxEditor {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
    }

    .CodeMirror {
        border: 1px solid #eee;
        height: auto;
        margin-bottom: 0.7rem;

        pre {
            white-space: pre !important;
        }
    }
}
